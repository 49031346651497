import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'emailler',
  components: { DatePicker },
  watch: {
    currentPage: function() {
      this.getEmaillerData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEmaillerData();
    }
  },
  data() {
    return {
      loading: false,
      panNum: null,
      vendorName: null,
      status: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      startDate: null,
      endDate: null,
      showValueSetModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      emaillerData: [],
      emaillerFields: [
        {
          key: 'pan',
          label: 'Pan Number',
          class: 'text-center'
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          class: 'text-center'
        },
        {
          key: 'sub',
          label: 'Subject',
          class: 'text-center wid-fix-sub'
        },
        // {
        //   key: 'receipent',
        //   label: 'Recipient',
        //   class: 'text-center'
        // },
        // {
        //   key: 'comm_type',
        //   label: 'Recipient Type',
        //   class: 'text-center'
        // },
        {
          key: 'TO_EMAILS',
          label: 'Email To'
          // class: 'text-center'
        },
        {
          key: 'CC_EMAILS',
          label: 'Email CC'
          // class: 'text-center'
        },
        {
          key: 'BCC_EMAILS',
          label: 'Email BCC'
          // class: 'text-center'
        },
        {
          key: 'sent_status',
          label: 'Status',
          class: 'text-center'
        },
        {
          key: 'sent_date1',
          label: 'Sent Date',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      downPayload: null
    };
  },
  mounted() {
    this.getEmaillerData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.downPayload };
          downloadpayload._limit = 1000;
          this.downloadExcel.downloadData(
            downloadpayload,
            'emailler/getEmaillerDtls',
            'email-dashboard',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getEmaillerData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        pan: this.panNum,
        vendor_name: this.vendorName,
        sent_date_from: this.startDate
          ? this.changeDateFormat(this.startDate)
          : null,
        sent_date_to: this.endDate ? this.changeDateFormat(this.endDate) : null,
        sent_status: this.status.value
      };
      this.downPayload = payload;
      this.loading = true;
      this.$store
        .dispatch('emailler/getEmaillerDtls', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.emaillerData = resp.data.data.page;
            this.emaillerData.map(
              ele =>
                (ele.sent_status =
                  ele.sent_status == 'F'
                    ? 'FAILED'
                    : ele.sent_status == 'S'
                    ? 'SENT'
                    : ele.sent_status == 'C'
                    ? 'CANCEL'
                    : ele.sent_status == 'H'
                    ? 'HOLD'
                    : ele.sent_status == 'I'
                    ? 'IN PROCESS'
                    : ele.sent_status == 'R'
                    ? 'READY TO PROCESS'
                    : '')
            );
            this.emaillerData.map(ele => {
              const emailStr1 = ele.TO_EMAILS;
              const emailStr2 = ele.CC_EMAILS;
              const emailStr3 = ele.BCC_EMAILS;
              ele.TO_EMAILS = ele.TO_EMAILS
                ? ele.TO_EMAILS.split(',').map(email => email.trim())
                : [];
              if (ele.TO_EMAILS.length === 1 && emailStr1.includes('@')) {
                ele.TO_EMAILS = [emailStr1.trim()];
              }
              ele.CC_EMAILS = ele.CC_EMAILS
                ? ele.CC_EMAILS.split(',').map(email => email.trim())
                : [];
              if (ele.CC_EMAILS.length === 1 && emailStr2.includes('@')) {
                ele.CC_EMAILS = [emailStr2.trim()];
              }
              ele.BCC_EMAILS = ele.BCC_EMAILS
                ? ele.BCC_EMAILS.split(',').map(email => email.trim())
                : [];
              if (ele.BCC_EMAILS.length === 1 && emailStr3.includes('@')) {
                ele.BCC_EMAILS = [emailStr3.trim()];
              }
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SENT_EMAIL_STATUS) {
        this.status = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clear() {
      this.emaillerData = [];
      this.panNum = null;
      this.vendorName = null;
      this.startDate = null;
      this.endDate = null;
      this.status = this.defaultValue;
      this.getEmaillerData();
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.status.value) {
        this.status = this.defaultValue;
      } else if (vsetCode === 'vendorName') {
        this.vendorName = null;
      } else if (vsetCode === 'panNum') {
        this.panNum = null;
      }
    },
    changeDateFormat(date) {
      let parsedDate = new Date(date);
      let year = parsedDate.getFullYear();
      let month = parsedDate.getMonth() + 1;
      let day = parsedDate.getDate();
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      let formattedDate = year + '-' + month + '-' + day;
      return formattedDate;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
